import { useMemo, useRef, useState } from 'react'

import Lottie, { LottieRefCurrentProps } from 'lottie-react'
import { Show } from '../helpers/Conditionals'
import mapUiPanel from './../assets/svg/map_UI_panel.svg'

const Screen14 = (props: { onClick: () => void }) => {
  const screenRef = useRef<LottieRefCurrentProps>(null)
  const [step, setStep] = useState<number>(0)
  const steps = useMemo(
    () => [
      {
        onClick: () => {},
      },
      {
        onClick: () => setStep(2),
      },
      {
        question:
          'Well done! You completed your first level! Remember you can revisit your journey anytime, just click on the little icons on your map',
        img: mapUiPanel,
        onClick: () => props.onClick(),
      },
    ],
    []
  )

  return (
    <div
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
        padding: 0,
        overflow: 'hidden',
      }}
      onClick={steps[step].onClick}
    >
      <Lottie
        lottieRef={screenRef}
        autoplay
        loop={false}
        animationData={require('./../assets/ui/map_animation_3.json')}
        onComplete={() => setStep(1)}
        style={{
          width: window.innerWidth,
          zoom: 1.5,
          transform: 'translate(-50%, -50%)',
          position: 'fixed',
          left: '41%',
          top: '50%',
        }}
      />
      <Show when={step === 2}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '50%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          {steps[step].question}
        </h3>
      </Show>
    </div>
  )
}
export default Screen14
