import { useRef, useState, useMemo } from 'react'

import Lottie, { LottieRefCurrentProps } from 'lottie-react'

import { Show } from '../helpers/Conditionals'

const Screen7 = (props: { onClick: () => void }) => {
  const [step, setStep] = useState<number>(0)
  const screenRef = useRef<LottieRefCurrentProps>(null)
  const textBubbleRef = useRef<LottieRefCurrentProps>(null)
  const steps = useMemo(
    () => [
      { onClick: () => setStep(1) },
      {
        question: 'For example',
        onClick: () => setStep(2),
      },
      {
        question: 'when you receive a friendly message',
        onClick: () => setStep(3),
      },
      {
        question: 'You immediately feel happiness for a few seconds',
        onClick: () => setStep(4),
      },
      { question: 'You probably even smile', onClick: () => setStep(5) },
      {
        question: 'and then the emotion disappears.',
        onClick: () => setStep(6),
      },
      {
        question: 'It’s essential to be able to identify emotions',
        onClick: () => setStep(7),
      },
      {
        question: 'as emotions and feelings are tightly linked to moods.',
        onClick: () => setStep(8),
      },
      {
        question: 'Moods and Emotions are 2 different elements.',
        onClick: () => {
          setStep(9)
          screenRef.current?.setDirection(1)
        },
      },
      {
        question: '',
        onClick: () => {},
      },
    ],
    []
  )

  return (
    <div
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
        padding: 0,
        overflow: 'hidden',
      }}
      onClick={steps[step].onClick}
    >
      <Lottie
        lottieRef={screenRef}
        autoplay
        loop={false}
        onComplete={() => props.onClick()}
        animationData={require('./../assets/screens/screen7.json')}
        onEnterFrame={frame => {
          switch (parseInt(JSON.parse(JSON.stringify(frame)).currentTime)) {
            case 154:
              screenRef.current?.setDirection(1)
              break
            case 155:
              step < 1 ? screenRef.current?.setDirection(-1) : null
              break
            case 185:
              screenRef.current?.setDirection(1)
              break
            case 275:
              step < 9 ? screenRef.current?.setDirection(-1) : null
              break
            default:
              break
          }
        }}
        style={{
          width: window.innerWidth,
          zoom: 1.4,
        }}
      />
      <Show when={steps[step]?.question ? true : false}>
        <Lottie
          lottieRef={textBubbleRef}
          autoplay
          loop={true}
          animationData={require('./../assets/ui/text_bubble_animation.json')}
          onEnterFrame={() => textBubbleRef.current?.setSpeed(0.25)}
          style={{
            position: 'fixed',
            top: '20%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '30rem',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '20%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
          }}
          className="text-2xl text-center"
        >
          {steps[step].question}
        </h3>
      </Show>
    </div>
  )
}
export default Screen7
