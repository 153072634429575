import { useEffect, useRef, useState } from 'react'

import Lottie, { LottieRefCurrentProps } from 'lottie-react'
import closeButton from './../assets/svg/close_icon.svg'

const ScreenMap = (props: {
  startLearn: () => void
  startMeasure: () => void
  startDo: () => void
  showScreenHome: () => void
}) => {
  const [alreadyPaused, setAlreadyPaused] = useState<boolean>(false)
  const screenRef = useRef<LottieRefCurrentProps>(null)
  const onClick = () => {
    setAlreadyPaused(true)
    screenRef.current?.play()
  }
  const attachListneres = () => {
    document
      .getElementById('learnicon')
      ?.addEventListener('click', props.startLearn)

    document
      .getElementById('measureicon')
      ?.addEventListener('click', props.startMeasure)

    document.getElementById('doicon')?.addEventListener('click', props.startDo)
  }

  useEffect(() => {
    // 👇️ remove the event listener when the component unmounts
    return () => {
      document
        .getElementById('learnicon')
        ?.removeEventListener('click', props.startLearn)
      document
        .getElementById('measureicon')
        ?.removeEventListener('click', props.startMeasure)
      document
        .getElementById('learnicon')
        ?.removeEventListener('doicon', props.startDo)
    }
  }, [])

  return (
    <div
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
        padding: 0,
        overflow: 'hidden',
      }}
      onClick={onClick}
    >
      <Lottie
        lottieRef={screenRef}
        autoplay
        loop={false}
        onComplete={attachListneres}
        animationData={require('./../assets/ui/map_animation_3.json')}
        onEnterFrame={frame => {
          switch (parseInt(JSON.parse(JSON.stringify(frame)).currentTime)) {
            case 300:
              alreadyPaused ? null : screenRef.current?.pause()
              break
            default:
              break
          }
        }}
        style={{
          width: window.innerWidth,
          zoom: 1.5,
          transform: 'translate(-50%, -50%)',
          position: 'fixed',
          left: '41%',
          top: '50%',
        }}
      />
      <span onClick={() => props.showScreenHome()}>
        <img
          src={closeButton}
          style={{
            position: 'fixed',
            top: '5%',
            left: ' 90%',
            transform: 'translate(-50%, -50%)',
            width: '10%',
          }}
          onClick={() => props.showScreenHome()}
        />
      </span>
    </div>
  )
}
export default ScreenMap
