import { useRef, useState, useMemo } from 'react'

import Lottie, { LottieRefCurrentProps } from 'lottie-react'

import { Show } from '../helpers/Conditionals'

const Screen5 = (props: { onClick: () => void }) => {
  const [step, setStep] = useState<number>(0)
  const screenRef = useRef<LottieRefCurrentProps>(null)
  const iconRef = useRef<LottieRefCurrentProps>(null)
  const textBubbleRef = useRef<LottieRefCurrentProps>(null)
  const steps = useMemo(
    () => [
      { onClick: () => setStep(1) },
      {
        question: 'Welcome to the path to Mount Emotions!',
        onClick: () => setStep(2),
      },
      {
        question: 'On your hike, you will have sunny moments but...',
        onClick: () => setStep(3),
      },
      {
        question: 'this path is also known for its change of weather',
        onClick: () => setStep(4),
      },
      { question: 'Soon enough, the sky changes', onClick: () => setStep(5) },
      {
        question: 'And dark clouds are coming your way.',
        onClick: () => setStep(6),
      },
      {
        question: 'Emotions are like these dark clouds',
        onClick: () => setStep(7),
      },
      { question: '...they come and go', onClick: () => setStep(8) },
      {
        question: 'So you need to be ready to face them',
        onClick: () => setStep(9),
      },
      {
        question: 'Because you can’t control the fact they’re coming',
        onClick: () => setStep(10),
      },
      {
        question: 'However you can learn how to deal with them',
        onClick: () => setStep(11),
      },
      {
        question: 'Until the clouds are gone and the sun comes back.',
        onClick: () => {
          setStep(12)
          screenRef.current?.setDirection(1)
        },
      },
      {
        question: '',
        onClick: () => {},
      },
    ],
    []
  )

  return (
    <div
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
        padding: 0,
        overflow: 'hidden',
      }}
      onClick={steps[step].onClick}
    >
      <Lottie
        lottieRef={screenRef}
        autoplay
        loop={false}
        onComplete={() => props.onClick()}
        animationData={require('./../assets/screens/screen5.json')}
        onEnterFrame={frame => {
          switch (parseInt(JSON.parse(JSON.stringify(frame)).currentTime)) {
            case 0:
              screenRef.current?.setDirection(1)
              break
            case 50:
              step < 4 ? screenRef.current?.setDirection(-1) : null
              break
            case 300:
              screenRef.current?.setDirection(1)
              break
            case 350:
              step < 12 ? screenRef.current?.setDirection(-1) : null
              break
            default:
              break
          }
        }}
        style={{
          width: window.innerWidth,
          zoom: 1.4,
        }}
      />
      <Show when={steps[step]?.question ? true : false}>
        <Lottie
          lottieRef={textBubbleRef}
          autoplay
          loop={true}
          animationData={require('./../assets/ui/text_bubble_animation.json')}
          onEnterFrame={() => textBubbleRef.current?.setSpeed(0.25)}
          style={{
            position: 'fixed',
            top: '20%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '30rem',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '20%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
          }}
          className="text-2xl text-center"
        >
          {steps[step].question}
        </h3>
      </Show>
      <Show when={step === 0}>
        <Lottie
          lottieRef={iconRef}
          autoplay
          loop={true}
          animationData={require('./../assets/ui/tap_icon.json')}
          initialSegment={[100, 950]}
          onLoopComplete={() => {
            iconRef.current?.playSegments([500, 620], true)
          }}
          onEnterFrame={() => iconRef.current?.setSpeed(2)}
          style={{
            width: '5rem',
            position: 'fixed',
            top: '75%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </Show>
    </div>
  )
}
export default Screen5
