import Lottie from 'lottie-react'

import { Show } from '../helpers/Conditionals'

const Screen3Transition = (props: {
  toDisplay: () => void
  onComplete: () => void
}) => {
  return (
    <div
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
        padding: 0,
        overflow: 'hidden',
      }}
    >
      <Show when={true}>
        <Lottie
          onComplete={() => props.onComplete()}
          autoplay
          loop={false}
          animationData={require('./../assets/ui/cloud_animation.json')}
          onEnterFrame={frame => {
            if (
              parseInt(JSON.parse(JSON.stringify(frame)).currentTime) === 140
            ) {
              props.toDisplay()
            }
          }}
          style={{
            width: window.innerWidth,
            position: 'fixed',
            top: '50%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 10,
            zoom: 1.5,
          }}
        />
      </Show>
    </div>
  )
}
export default Screen3Transition
