import { useRef, useState, useMemo } from 'react'

import Lottie, { LottieRefCurrentProps } from 'lottie-react'

import { Show } from '../helpers/Conditionals'

const Screen8 = (props: { onClick: () => void }) => {
  const [step, setStep] = useState<number>(0)
  const screenRef = useRef<LottieRefCurrentProps>(null)
  const textBubbleRef = useRef<LottieRefCurrentProps>(null)
  const steps = useMemo(
    () => [
      { onClick: () => setStep(1) },
      {
        question: 'So, let’s talk about moods and what they entail.',
        onClick: () => setStep(2),
      },
      {
        question: 'Moods are often not tied to a  specific event',
        onClick: () => setStep(3),
      },
      {
        question: 'They last longer than emotions, sometimes even days',
        onClick: () => setStep(4),
      },
      {
        question: 'It can be hard to identify where they come from',
        onClick: () => setStep(5),
      },
      {
        question: 'Some of us have more positive moods',
        onClick: () => setStep(6),
      },
      {
        question: 'also called in psychology “Positive Affectivity”',
        onClick: () => setStep(7),
      },
      {
        question: 'while others have more negative moods',
        onClick: () => setStep(8),
      },
      {
        question: 'also called “Negative Affectivity”',
        onClick: () => {
          setStep(9)
          screenRef.current?.setDirection(1)
        },
      },
      {
        question: '',
        onClick: () => {},
      },
    ],
    []
  )

  return (
    <div
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
        padding: 0,
        overflow: 'hidden',
      }}
      onClick={steps[step].onClick}
    >
      <Lottie
        lottieRef={screenRef}
        autoplay
        loop={false}
        onComplete={() => props.onClick()}
        animationData={require('./../assets/screens/screen8.json')}
        onEnterFrame={frame => {
          switch (parseInt(JSON.parse(JSON.stringify(frame)).currentTime)) {
            case 375:
              step < 9 ? screenRef.current?.goToAndPlay(325, true) : null
              break
            default:
              break
          }
        }}
        style={{
          width: window.innerWidth,
          zoom: 1.4,
        }}
      />
      <Show when={steps[step]?.question ? true : false}>
        <Lottie
          lottieRef={textBubbleRef}
          autoplay
          loop={true}
          animationData={require('./../assets/ui/text_bubble_animation.json')}
          onEnterFrame={() => textBubbleRef.current?.setSpeed(0.25)}
          style={{
            position: 'fixed',
            top: '20%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '30rem',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '20%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
          }}
          className="text-2xl text-center"
        >
          {steps[step].question}
        </h3>
      </Show>
    </div>
  )
}
export default Screen8
