import { useRef, useState, useMemo } from 'react'

import Lottie, { LottieRefCurrentProps } from 'lottie-react'

import { Show } from '../helpers/Conditionals'

const Screen9 = (props: { onClick: () => void }) => {
  const [step, setStep] = useState<number>(0)
  const screenRef = useRef<LottieRefCurrentProps>(null)
  const textBubbleRef = useRef<LottieRefCurrentProps>(null)
  const steps = useMemo(
    () => [
      { onClick: () => setStep(1) },
      {
        question: 'Positive Affectivity means you are more likely...',
        onClick: () => setStep(2),
      },
      {
        question: 'to feel positive emotions',
        onClick: () => setStep(3),
      },
      {
        question: 'to be more energised',
        onClick: () => setStep(4),
      },
      {
        question: 'to be more optimistic',
        onClick: () => setStep(5),
      },
      {
        question: 'to be more satisfied with your life',
        onClick: () => setStep(6),
      },
      {
        question: 'and to have an overall healthy wellbeing',
        onClick: () => {
          setStep(7)
          screenRef.current?.setDirection(1)
        },
      },
      {
        question: '',
        onClick: () => {},
      },
    ],
    []
  )

  return (
    <div
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
        padding: 0,
        overflow: 'hidden',
      }}
      onClick={steps[step].onClick}
    >
      <Lottie
        lottieRef={screenRef}
        autoplay
        loop={false}
        onComplete={() => props.onClick()}
        animationData={require('./../assets/screens/screen9.json')}
        onEnterFrame={frame => {
          switch (parseInt(JSON.parse(JSON.stringify(frame)).currentTime)) {
            case 350:
              screenRef.current?.setDirection(1)
              break
            case 508:
              step < 7 ? screenRef.current?.setDirection(-1) : null
              break
            default:
              break
          }
        }}
        style={{
          width: window.innerWidth,
          zoom: 1.4,
        }}
      />
      <Show when={steps[step]?.question ? true : false}>
        <Lottie
          lottieRef={textBubbleRef}
          autoplay
          loop={true}
          animationData={require('./../assets/ui/text_bubble_animation.json')}
          onEnterFrame={() => textBubbleRef.current?.setSpeed(0.25)}
          style={{
            position: 'fixed',
            top: '20%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '30rem',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '20%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
          }}
          className="text-2xl text-center"
        >
          {steps[step].question}
        </h3>
      </Show>
    </div>
  )
}
export default Screen9
