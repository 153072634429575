import { useRef, useState, useMemo, useEffect } from 'react'

import Lottie, { LottieRefCurrentProps } from 'lottie-react'

import { Show } from '../helpers/Conditionals'
import measureIcon from './../assets/svg/static_measure_icon.svg'
import measureUiPanel from './../assets/svg/measure_UI_panel.svg'
import answerImg from './../assets/svg/generic_white_UI_button_smaller.svg'
import rewardUiPanel from './../assets/svg/reward_UI_panel_end_of_learn.svg'
import measureBtn1 from './../assets/svg/measure_button_1.svg'
import measureBtn2 from './../assets/svg/measure_button_2.svg'
import measureBtn3 from './../assets/svg/measure_button_3.svg'
import measureBtn4 from './../assets/svg/measure_button_4.svg'
import measureBtn5 from './../assets/svg/measure_button_5.svg'
import jar from './../assets/svg/jar_frame.svg'
import light from './../assets/svg/single_firely_to_animate.svg'

export enum Data {
  none = 'none',
  small = 'small',
  medium = 'medium',
  big = 'big',
}

export enum DataImg {
  none = '',
  small = 'static_screen_1_small_light.png',
  medium = 'static_screen_2_medium_light.png',
  big = 'static_screen_3_big_light.png',
}

const Screen12 = (props: { onClick: () => void }) => {
  const [step, setStep] = useState<number>(0)
  const [question, setQuestion] = useState<number>(0)
  const [positiveScore, setPositiveScore] = useState<number>(0)
  const [negativeScore, setNegativeScore] = useState<number>(0)
  const positiveQuestions = [1, 3, 5, 9, 10, 12, 14, 16, 17, 19]
  // const negativeQuestions = [2, 4, 6, 7, 8, 11, 13, 15, 18, 20]
  const screenRef = useRef<LottieRefCurrentProps>(null)
  const iconRef1 = useRef<LottieRefCurrentProps>(null)
  const iconRef2 = useRef<LottieRefCurrentProps>(null)
  const iconRef3 = useRef<LottieRefCurrentProps>(null)
  const textBubbleRef = useRef<LottieRefCurrentProps>(null)
  useEffect(() => {
    if (step === 8) {
      localStorage.setItem(
        'Data',
        positiveScore < negativeScore
          ? Data.small
          : negativeScore > 25
          ? Data.medium
          : Data.big
      )
    }
  }, [step])
  const steps = useMemo(
    () => [
      {
        onClick: () => {},
      },
      {
        question: 'Let’s continue our journey',
        onClick: () => setStep(2),
      },
      {
        question:
          'The landscape is changing as we’re coming closer to the top of Mount Emotions',
        onClick: () => setStep(3),
      },
      {
        question:
          'A new element has appeared. This is the first Measure icon. Tap on it.',
        img: measureIcon,
        onClick: () => setStep(4),
      },
      {
        questionWithAnswers:
          'Now, you can assess yourself. Let’s use the PANAS scale (Positive and Negative Affect Scale) to help you understand what your main affectivity is. We will need you to fill in the entire questionnaire to provide you your score and feedback',
        img: measureUiPanel,
        onClick: () => setStep(5),
      },
      {
        questionWithAnswers:
          'For each emotion and feeling, indicate to which extent you have felt like this right now or in the past 2 weeks',
        img: measureUiPanel,
        onClick: () => {},
      },
      {
        questionWithAnswers:
          'If you decide to skip now, we won’t be able to calculate your score and provide you with a feedback',
        img: measureUiPanel,
        onClick: () => {},
      },
      {
        img: measureUiPanel,
        questionWithAnswers: [
          '1/20 interested',
          '2/20 Distressed',
          '3/20 Excited',
          '4/20 Upset',
          '5/20 Strong',
          '6/20 Guilty',
          '7/20 Scared',
          '8/20 Hostile',
          '9/20 Enthusiastic',
          '10/20 Proud',
          '11/20 Irritable',
          '12/20 Alerted',
          '13/20 Ashamed',
          '14/20 Inspired',
          '15/20 Nervous',
          '16/20 Determined',
          '17/20 Attentive',
          '18/20 Jittery',
          '19/20 Active',
          '20/20 Afraid',
        ],
        onClick: () => {
          //setStep(8)
        },
      },
      {
        questionWithAnswers: 'Congratulations, you finished the PANAS!',
        img: measureUiPanel,
        onClick: () => setStep(9),
      },
      {
        questionWithAnswers: 'Let’s calculate your score',
        img: measureUiPanel,
        onClick: () => setStep(10),
      },
      {
        questionWithAnswers: '',
        img: measureUiPanel,
        onClick: () => setStep(11),
      },
      {
        questionWithAnswers: 'Your Feedback',
        option1: `Your score indicates that you have been experiencing more positive moments than negative ones. This is great!

        Your Positive Affect can be further developed by practising positive activities and by treating yourself.
        
        Your journey will provide you with tools and techniques to cultivate your Positive Affect even more.`,
        option2: `Your score indicates that you have experienced more positive moments than negative ones. 

        However it also indicates that there have been some struggles and challenges along the way lately. 
        
        Your journey will provide you with tools and techniques to cultivate your Positive Affect and reduce the impact of negative feelings and emotions.`,
        option3: `Your score indicates that you have experienced more negative than positive moments lately. 


        Your journey will provide you with tools and techniques to cultivate your Positive Affect, to experience more positive moments. It will also reduce the impact of stressors and negative feelings you currently experience.`,
        img: measureUiPanel,
        onClick: () => setStep(12),
      },
      {
        questionWithAnswers: '',
        onClick: () => setStep(13),
      },
      {
        questionWithAnswers:
          'Congratulations! You have just finished your first Measure!',
        img: measureUiPanel,
        onClick: () => setStep(14),
      },
      {
        questionWithAnswers:
          'You have also collected all the fireflies for this section!',
        img: rewardUiPanel,
        img2: jar,
        img3: light,
        onClick: () => setStep(15),
      },
      {
        questionWithAnswers: 'And here is your Measure icon!',
        img: measureUiPanel,
        img2: measureIcon,
        onClick: () => {
          setStep(16)
          screenRef.current?.setDirection(1)
        },
      },
      {
        question: '',
        onClick: () => screenRef.current?.setDirection(1),
      },
    ],
    []
  )

  return (
    <div
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
        padding: 0,
        overflow: 'hidden',
      }}
      onClick={steps[step].onClick}
    >
      <Lottie
        lottieRef={screenRef}
        autoplay
        loop={false}
        onComplete={() => props.onClick()}
        animationData={require('./../assets/screens/screen12.json')}
        onEnterFrame={frame => {
          switch (parseInt(JSON.parse(JSON.stringify(frame)).currentTime)) {
            case 155:
              setStep(1)
              break
            case 250:
              screenRef.current?.setDirection(1)
              break
            case 265:
              step < 2 ? screenRef.current?.setDirection(-1) : null
              break
            case 400:
              screenRef.current?.setDirection(1)
              break
            case 500:
              step < 13
                ? screenRef.current?.setDirection(-1)
                : screenRef.current?.setDirection(1)
              break
            default:
              break
          }
        }}
        style={{
          width: window.innerWidth,
          zoom: 1.4,
        }}
      />

      <Show when={steps[step]?.question ? true : false}>
        <Lottie
          lottieRef={textBubbleRef}
          autoplay
          loop={true}
          animationData={require('./../assets/ui/text_bubble_animation.json')}
          onEnterFrame={() => textBubbleRef.current?.setSpeed(0.25)}
          style={{
            position: 'fixed',
            top: '20%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '30rem',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '20%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
          }}
          className="text-2xl text-center"
        >
          {steps[step].question}
        </h3>
      </Show>
      <Show when={step === 3}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '75%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '10rem',
          }}
        />
      </Show>
      <Show when={step === 4}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '60%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '45%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          {steps[step].questionWithAnswers}
        </h3>
        <Lottie
          lottieRef={iconRef1}
          autoplay
          loop={true}
          animationData={require('./../assets/ui/tap_icon.json')}
          initialSegment={[100, 950]}
          onLoopComplete={() => {
            iconRef1.current?.playSegments([500, 620], true)
          }}
          onEnterFrame={() => iconRef1.current?.setSpeed(2)}
          style={{
            width: '5rem',
            position: 'fixed',
            top: '68%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </Show>
      <Show when={step === 5}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '60%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '40%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          {steps[step].questionWithAnswers}
        </h3>
        <img
          src={answerImg}
          style={{
            position: 'fixed',
            top: '53%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
        />
        <p
          style={{
            position: 'fixed',
            top: '53%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
          className="text-l text-center"
          onClick={() => setStep(7)}
        >
          Let's do it
        </p>
        <img
          src={answerImg}
          style={{
            position: 'fixed',
            top: '59%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
        />
        <p
          style={{
            position: 'fixed',
            top: '59%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
          className="text-l text-center"
          onClick={() => setStep(6)}
        >
          Skip for now
        </p>
      </Show>
      <Show when={step === 6}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '60%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '40%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          {steps[step].questionWithAnswers}
        </h3>
        <img
          src={answerImg}
          style={{
            position: 'fixed',
            top: '53%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
        />
        <p
          style={{
            position: 'fixed',
            top: '53%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
          className="text-l text-center"
          onClick={() => setStep(15)}
        >
          Yes, I want to skip
        </p>
        <img
          src={answerImg}
          style={{
            position: 'fixed',
            top: '59%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
        />
        <p
          style={{
            position: 'fixed',
            top: '59%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
          className="text-l text-center"
          onClick={() => setStep(7)}
        >
          No, let’s do the assessment
        </p>
      </Show>
      <Show when={step === 7}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '60%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '32%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          {steps[7].questionWithAnswers
            ? steps[7].questionWithAnswers[question]
            : ''}
        </h3>
        <img
          src={measureBtn1}
          className="active:opacity-50"
          style={{
            position: 'fixed',
            top: '40%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
          onClick={() => {
            positiveQuestions.includes(question + 1)
              ? setPositiveScore(positiveScore + 1)
              : setNegativeScore(negativeScore + 1)
            setQuestion(question + 1)
            question === 19 ? setStep(8) : null
          }}
        />
        <img
          className="active:opacity-50"
          src={measureBtn2}
          style={{
            position: 'fixed',
            top: '46%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
          onClick={() => {
            positiveQuestions.includes(question + 1)
              ? setPositiveScore(positiveScore + 2)
              : setNegativeScore(negativeScore + 2)
            setQuestion(question + 1)
            question === 19 ? setStep(8) : null
          }}
        />
        <img
          className="active:opacity-50"
          src={measureBtn3}
          style={{
            position: 'fixed',
            top: '52%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
          onClick={() => {
            positiveQuestions.includes(question + 1)
              ? setPositiveScore(positiveScore + 3)
              : setNegativeScore(negativeScore + 3)
            setQuestion(question + 1)
            question === 19 ? setStep(8) : null
          }}
        />
        <img
          className="active:opacity-50"
          src={measureBtn4}
          style={{
            position: 'fixed',
            top: '58%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
          onClick={() => {
            positiveQuestions.includes(question + 1)
              ? setPositiveScore(positiveScore + 4)
              : setNegativeScore(negativeScore + 4)
            setQuestion(question + 1)
            question === 19 ? setStep(8) : null
          }}
        />
        <img
          className="active:opacity-50"
          src={measureBtn5}
          style={{
            position: 'fixed',
            top: '64%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
          onClick={() => {
            positiveQuestions.includes(question + 1)
              ? setPositiveScore(positiveScore + 5)
              : setNegativeScore(negativeScore + 5)
            setQuestion(question + 1)
            question === 19 ? setStep(8) : null
          }}
        />
      </Show>
      <Show when={step === 8}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '60%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '45%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          {steps[step].questionWithAnswers}
        </h3>
        <Lottie
          autoplay
          loop={true}
          animationData={require('./../assets/ui/confetti_pop.json')}
          style={{
            width: '15rem',
            position: 'fixed',
            top: '55%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </Show>
      <Show when={step === 9}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '60%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '45%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          {steps[step].questionWithAnswers}
        </h3>
        <Lottie
          lottieRef={iconRef3}
          autoplay
          loop={false}
          animationData={require('./../assets/ui/measure_result_processing_icon_animation.json')}
          onComplete={() => steps[step].onClick()}
          onEnterFrame={() => iconRef3.current?.setSpeed(0.3)}
          style={{
            width: '15rem',
            position: 'fixed',
            top: '55%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </Show>
      <Show when={step === 10}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '60%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
          }}
        />
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-3xl text-center"
        >
          <div>
            <h4>Your Positive Affect Score:</h4>
            <h5 className="mt-10">{positiveScore}</h5>
          </div>
          <div className="mt-40">
            <h4>Your Negative Affect Score:</h4>
            <h5 className="mt-10">{negativeScore}</h5>
          </div>
        </div>
      </Show>
      <Show when={step === 11}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '60%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '12%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          {steps[step].questionWithAnswers}
        </h3>
        <h3
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          {positiveScore < negativeScore
            ? steps[step].option3
            : negativeScore > 25
            ? steps[step].option2
            : steps[step].option1}
        </h3>
        <Lottie
          lottieRef={iconRef2}
          autoplay
          loop={true}
          animationData={require('./../assets/ui/tap_icon.json')}
          initialSegment={[100, 950]}
          onLoopComplete={() => {
            iconRef2.current?.playSegments([500, 620], true)
          }}
          onEnterFrame={() => iconRef2.current?.setSpeed(2)}
          style={{
            width: '5rem',
            position: 'fixed',
            top: '80%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </Show>
      <Show when={step === 12}>
        <img
          src={require('./../assets/ui/' +
            (positiveScore < negativeScore
              ? DataImg['small']
              : negativeScore > 25
              ? DataImg['medium']
              : DataImg['big']))}
          style={{
            position: 'fixed',
            top: '0%',
            width: '100%',
            height: '100%',
          }}
        />
      </Show>
      <Show when={step === 13}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '60%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '45%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          {steps[step].questionWithAnswers}
        </h3>
        <Lottie
          autoplay
          loop={true}
          animationData={require('./../assets/ui/confetti_pop.json')}
          style={{
            width: '15rem',
            position: 'fixed',
            top: '55%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </Show>
      <Show when={step === 14}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '50%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '45%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          {steps[step].questionWithAnswers}
        </h3>
        <img
          src={steps[step].img2}
          style={{
            position: 'fixed',
            top: '60%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '4rem',
          }}
        />
        <img
          src={steps[step].img3}
          style={{
            position: 'fixed',
            top: '60%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '6rem',
          }}
        />
      </Show>
      <Show when={step === 15}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '60%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '30%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          {steps[step].questionWithAnswers}
        </h3>
        <h3
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          It will be added to your map now so that you can assess yourself again
          or finish an ongoing assessment.
        </h3>
        <img
          src={steps[step].img2}
          style={{
            position: 'fixed',
            top: '70%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '6rem',
          }}
        />
      </Show>
    </div>
  )
}
export default Screen12
