import { useRef, useState, useMemo } from 'react'

import Lottie, { LottieRefCurrentProps } from 'lottie-react'
import uiPanel2 from './../assets/svg/lighten_up_UI_panel_2.svg'
import answerImg from './../assets/svg/generic_white_UI_button_smaller.svg'
import measureUiPanel from './../assets/svg/firefly_reward_panel_lighten_up_tutorial_2.svg'
import rewardUiPanel from './../assets/svg/reward_UI_panel_end_of_learn.svg'
import jar from './../assets/svg/jar_frame.svg'
import light from './../assets/svg/single_firely_to_animate.svg'
import learnIcon from './../assets/svg/static_learn_icon.svg'

import { Show } from '../helpers/Conditionals'

const Screen11 = (props: { onClick: () => void }) => {
  const [step, setStep] = useState<number>(0)
  const screenRef = useRef<LottieRefCurrentProps>(null)
  const steps = useMemo(
    () => [
      {
        onClick: () => {},
      },
      {
        question: 'Do not forget',
        img: uiPanel2,
        onClick: () => setStep(2),
      },
      {
        questionWithAnswers: 'Did you learn something',
        onClick: () => setStep(3),
        img: uiPanel2,
      },
      {
        questionWithAnswers: 'Congratulations',
        img: measureUiPanel,
        onClick: () => setStep(4),
      },
      {
        questionWithAnswers:
          'You have also collected all the fireflies for this section!',
        img: rewardUiPanel,
        img2: jar,
        img3: light,
        onClick: () => setStep(5),
      },
      {
        img: uiPanel2,
        img2: learnIcon,
        question: 'And here is your Learn icon!',
        onClick: () => {
          setStep(6)
          screenRef.current?.setDirection(1)
        },
      },
      {
        question: '',
        onClick: () => {},
      },
    ],
    []
  )

  return (
    <div
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
        padding: 0,
        overflow: 'hidden',
      }}
      onClick={steps[step].onClick}
    >
      <Lottie
        lottieRef={screenRef}
        autoplay
        loop={false}
        onComplete={() => props.onClick()}
        animationData={require('./../assets/screens/screen11.json')}
        onEnterFrame={frame => {
          switch (parseInt(JSON.parse(JSON.stringify(frame)).currentTime)) {
            case 155:
              setStep(1)
              break
            case 207:
              screenRef.current?.setDirection(1)
              break
            case 265:
              step < 6 ? screenRef.current?.setDirection(-1) : null
              break
            default:
              break
          }
        }}
        style={{
          width: window.innerWidth,
          zoom: 1.4,
        }}
      />
      <Show when={step === 1}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '45%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '30%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          {steps[step].question}
        </h3>
        <ul
          style={{
            position: 'fixed',
            top: '47%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            listStyleType: 'disc',
          }}
          className="text-xl"
        >
          <li>Emotions are reactions to an event and last seconds</li>
          <li>Moods can last longer</li>
          <li>Our mood tendency can be Positive Affect or Negative Affect</li>
        </ul>
      </Show>
      <Show when={step === 2}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '45%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '30%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          {steps[step].questionWithAnswers}
        </h3>
        <img
          src={answerImg}
          style={{
            position: 'fixed',
            top: '43%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
        />
        <p
          style={{
            position: 'fixed',
            top: '43%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
          className="text-l text-center"
        >
          Yes, let’s continue like this
        </p>
        <img
          src={answerImg}
          style={{
            position: 'fixed',
            top: '50%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
        />
        <p
          style={{
            position: 'fixed',
            top: '50%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
          className="text-l text-center"
        >
          Yes, but shorter next time
        </p>
        <img
          src={answerImg}
          style={{
            position: 'fixed',
            top: '57%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
        />
        <p
          style={{
            position: 'fixed',
            top: '57%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
          className="text-l text-center"
        >
          No, let’s skip it next time
        </p>
      </Show>
      <Show when={step === 3}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '50%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '35%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          {steps[step].questionWithAnswers}
        </h3>
        <Lottie
          autoplay
          loop={true}
          animationData={require('./../assets/ui/confetti_pop.json')}
          style={{
            width: '12rem',
            position: 'fixed',
            top: '45%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '60%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          You have just finished your first Learn!
        </h3>
      </Show>
      <Show when={step === 4}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '50%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '45%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          {steps[step].questionWithAnswers}
        </h3>
        <img
          src={steps[step].img2}
          style={{
            position: 'fixed',
            top: '60%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '4rem',
          }}
        />
        <img
          src={steps[step].img3}
          style={{
            position: 'fixed',
            top: '60%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '6rem',
          }}
        />
      </Show>
      <Show when={step === 5}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '45%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '30%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          {steps[step].question}
        </h3>
        <p
          style={{
            position: 'fixed',
            top: '47%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            listStyleType: 'disc',
          }}
          className="text-xl"
        >
          It will be added to your map and you can come back to it in the future
        </p>
        <img
          src={steps[step].img2}
          style={{
            position: 'fixed',
            top: '60%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '6rem',
          }}
        />
      </Show>
    </div>
  )
}
export default Screen11
