import { useRef, useState } from 'react'

import Lottie, { LottieRefCurrentProps } from 'lottie-react'
import { Show } from '../helpers/Conditionals'

const Screen3 = (props: { onClick: () => void }) => {
  const [alreadyPaused, setAlreadyPaused] = useState<boolean>(false)
  const [showTapIcon, setShowTapIcon] = useState<boolean>(false)
  const screenRef = useRef<LottieRefCurrentProps>(null)
  const iconRef = useRef<LottieRefCurrentProps>(null)

  const onClick = () => {
    setAlreadyPaused(true)
    setShowTapIcon(false)
    screenRef.current?.play()
  }

  return (
    <div
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
        padding: 0,
        overflow: 'hidden',
      }}
      onClick={onClick}
    >
      <Lottie
        lottieRef={screenRef}
        autoplay
        loop={false}
        onComplete={() => props.onClick()}
        animationData={require('./../assets/screens/screen3.json')}
        onEnterFrame={frame => {
          screenRef.current?.setSpeed(2)
          switch (parseInt(JSON.parse(JSON.stringify(frame)).currentTime)) {
            case 550:
              if (!alreadyPaused) {
                screenRef.current?.pause()
                setShowTapIcon(true)
              }
              break
            default:
              break
          }
        }}
        style={{
          width: window.innerWidth,
          zoom: 1.5,
          transform: 'translate(-50%, -50%)',
          position: 'fixed',
          left: '50%',
          top: '50%',
        }}
      />
      <Show when={showTapIcon}>
        <Lottie
          lottieRef={iconRef}
          autoplay
          loop={true}
          animationData={require('./../assets/ui/tap_icon.json')}
          initialSegment={[100, 950]}
          onLoopComplete={() => {
            iconRef.current?.playSegments([500, 620], true)
          }}
          onEnterFrame={() => iconRef.current?.setSpeed(2)}
          style={{
            width: '5rem',
            position: 'fixed',
            top: '68%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </Show>
    </div>
  )
}
export default Screen3
