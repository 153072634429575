import { useRef, useState } from 'react'

import Lottie, { LottieRefCurrentProps } from 'lottie-react'
import dataIcon from './../assets/svg/dashboard_data_button.svg'
import nextIcon from './../assets/svg/dashboard_next_button.svg'
import mapIcon from './../assets/svg/dashboard_map_button.svg'
import preOrderUiPanel from './../assets/svg/preorder_UI_panel.svg'
import closeButton from './../assets/svg/close_icon.svg'
import answerImg from './../assets/svg/preorder_UI_panel_button.svg'
import { Show } from '../helpers/Conditionals'

const ScreenHome = (props: {
  showMap: () => void
  showData: () => void
  setShowSupportModal: (show: boolean) => void
  showSupportModal: boolean
}) => {
  const [step, setStep] = useState<number>(0)
  const screenRef = useRef<LottieRefCurrentProps>(null)
  const logoRef = useRef<LottieRefCurrentProps>(null)

  return (
    <div
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
        padding: 0,
        overflow: 'hidden',
      }}
    >
      <Lottie
        lottieRef={screenRef}
        autoplay
        loop={false}
        animationData={require('./../assets/screens/screen1.json')}
        onEnterFrame={frame => {
          switch (parseInt(JSON.parse(JSON.stringify(frame)).currentTime)) {
            case 70:
              if (step === 0) {
                setStep(1)
              }
              break
            case 320:
              screenRef.current?.pause()
              break
            default:
              break
          }
        }}
        style={{
          width: window.innerWidth,
          zoom: 1.4,
        }}
      />
      <Lottie
        lottieRef={logoRef}
        autoplay
        loop={true}
        animationData={require('./../assets/ui/loading_screen_logo_animation.json')}
        initialSegment={[0, 400]}
        onLoopComplete={() => {
          logoRef.current?.playSegments([300, 400], true)
        }}
        style={{
          position: 'fixed',
          top: '10%',
          left: ' 50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
      <Show when={step > 0}>
        <Show when={!props.showSupportModal}>
          <div
            className="flex"
            style={{
              position: 'fixed',
              top: '80%',
              left: '50%',
              width: '80%',
              transform: 'translate(-50%, -50%)',
              background: 'rgba(46, 50, 71, 0.7)',
            }}
          >
            <div className="flex-1 flex flex-row" onClick={props.showMap}>
              <img src={mapIcon} style={{ width: '50%' }} className="m-auto" />
            </div>

            <div
              className="flex-1 flex flex-row"
              onClick={() =>
                window.open(
                  'https://buy.stripe.com/5kAdS1aWedfMcNifZ1',
                  '_blank'
                )
              }
            >
              <img
                src={nextIcon}
                style={{ height: '60%', width: '100%' }}
                className="m-auto"
              />
            </div>
            <div className="flex-1 flex flex-row" onClick={props.showData}>
              <img src={dataIcon} style={{ width: '50%' }} className="m-auto" />
            </div>
          </div>
        </Show>
        <Show when={props.showSupportModal}>
          <img
            src={preOrderUiPanel}
            style={{
              position: 'fixed',
              top: '70%',
              left: ' 50%',
              transform: 'translate(-50%, -50%)',
              width: '100%',
            }}
          />
          <span onClick={() => props.setShowSupportModal(false)}>
            <img
              src={closeButton}
              style={{
                position: 'fixed',
                top: '15%',
                left: ' 85%',
                transform: 'translate(-50%, -50%)',
                width: '10%',
              }}
              onClick={() => props.setShowSupportModal(false)}
            />
          </span>
          <h3
            style={{
              position: 'fixed',
              top: '25%',
              left: '50%',
              width: '80%',
              transform: 'translate(-50%, -50%)',
              color: 'white',
            }}
            className="text-2xl text-center"
          >
            The journey does not end here!
          </h3>
          <h4
            style={{
              position: 'fixed',
              top: '43%',
              left: '50%',
              width: '80%',
              transform: 'translate(-50%, -50%)',
              color: 'white',
            }}
            className="text-xl text-center"
          >
            This is just the beginning. <br />
            If you enjoyed Fireflai so far, you can support us now and get a
            thank-you gift: access to the next levels
          </h4>
          <a href="https://buy.stripe.com/5kAdS1aWedfMcNifZ1" target="_blank">
            <img
              src={answerImg}
              style={{
                position: 'fixed',
                top: '63%',
                left: ' 50%',
                transform: 'translate(-50%, -50%)',
                width: '60%',
              }}
            />
            <p
              style={{
                position: 'fixed',
                top: '63%',
                left: ' 50%',
                transform: 'translate(-50%, -50%)',
                width: '60%',
              }}
              className="text-l text-center text-white"
              onClick={() => setStep(4)}
            >
              I want to support Fireflai
            </p>
          </a>
          <a href="https://form.typeform.com/to/YcuoCy0H" target="_blank">
            <img
              src={answerImg}
              style={{
                position: 'fixed',
                top: '70%',
                left: ' 50%',
                transform: 'translate(-50%, -50%)',
                width: '60%',
              }}
            />
            <p
              style={{
                position: 'fixed',
                top: '70%',
                left: ' 50%',
                transform: 'translate(-50%, -50%)',
                width: '60%',
              }}
              className="text-l text-center text-white"
            >
              I just want to give feedback
            </p>
          </a>
        </Show>
      </Show>
    </div>
  )
}
export default ScreenHome
