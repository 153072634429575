import { useRef, useState, useMemo, Fragment, useEffect } from 'react'

import Lottie, { LottieRefCurrentProps } from 'lottie-react'
import doIcon from './../assets/svg/static_do_icon.svg'
import doUiPanel from './../assets/svg/do_UI_panel_1.svg'
import doUiPanel4 from './../assets/svg/do_UI_panel_4_sliding_down_from_the_cloud.svg'
import doInput from './../assets/svg/do_UI_panel_3_input_window.svg'
import answerImg from './../assets/svg/generic_white_UI_button_smaller.svg'
import yearlyMaker from './../assets/svg/yearly_marker_do_section.svg'
import jar from './../assets/svg/jar_frame.svg'
import light from './../assets/svg/single_firely_to_animate.svg'
import rewardUiPanel from './../assets/svg/reward_UI_panel_end_of_learn.svg'

import { Show } from '../helpers/Conditionals'

const Screen13 = (props: { onClick: () => void }) => {
  const [step, setStep] = useState<number>(0)
  const [year, setYear] = useState<1 | 3 | 5>()
  const [text, setText] = useState<string>('')
  const [domain, setDomain] = useState<'Personal' | 'Social' | 'Professional'>()
  const prompts = {
    Personal: ['skills', 'hobbies', 'accomplishments', 'personality', 'health'],
    Social: ['romantic relationship', 'friends', 'family', 'social activities'],
    Professional: [
      'skills',
      'job',
      'sense of purpose',
      'education',
      'retirement',
      'income',
      'success',
      'promotion',
    ],
  }
  const screenRef = useRef<LottieRefCurrentProps>(null)
  const textBubbleRef = useRef<LottieRefCurrentProps>(null)
  const steps = useMemo(
    () => [
      {
        onClick: () => {},
      },
      {
        question:
          'You have made it to the top of Mount Emotions! Congratulations!',
        onClick: () => setStep(2),
      },
      {
        question: 'A new element has appeared: it is the Do icon. Tap on it',
        img: doIcon,
        onClick: () => setStep(3),
      },
      {
        questionWithAnswers:
          'Now, let’s have some fun and practice the “Best Possible Self” (BPS) exercise. This is an evidence-based visualisation exercise which is very powerful to increase Positive Affect. We will do it together',
        img: doUiPanel,
        onClick: () => {},
      },
      {
        questionWithAnswers: 'Tap on the period you would like to focus on.',
        img: doUiPanel4,
        onClick: () => {},
      },
      {
        questionWithAnswers:
          'Select a domain you would like to focus on. Tap on it.',
        img: doUiPanel4,
        onClick: () => {},
      },
      {
        questionWithAnswers:
          'Picture yourself as very successful in this domain, with as much detail as possible. Use the prompts if needed and be ready to start writing about it',
        img: doUiPanel4,
        onClick: () => {},
      },
      {
        questionWithAnswers: '',
        img: doInput,
        onClick: () => {},
      },
      {
        questionWithAnswers:
          'Congratulations! You have just finished your first Do!',
        img: doUiPanel4,
        onClick: () => setStep(9),
      },
      {
        questionWithAnswers: '',
        img: doUiPanel,
        onClick: () => setStep(10),
      },
      {
        questionWithAnswers:
          'You have also collected all the fireflies for this section!',
        img: rewardUiPanel,
        img2: jar,
        img3: light,
        onClick: () => setStep(11),
      },
      {
        questionWithAnswers: 'And here is your Do icon!',
        img: doUiPanel,
        img2: doIcon,
        onClick: () => setStep(12),
      },
      {
        question:
          'It’s time to say goodbye to Mount Emotions. Let’s see what’s coming up next',
        onClick: () => props.onClick(),
      },
    ],
    []
  )

  useEffect(() => {
    setText(localStorage.getItem(year?.toString() + '-' + domain) ?? '')
  }, [year, domain])

  const saveText = () => {
    if (year && domain) {
      localStorage.setItem(year.toString() + '-' + domain, text)
    }
    setStep(8)
  }
  return (
    <div
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
        padding: 0,
        overflow: 'hidden',
      }}
      onClick={steps[step].onClick}
    >
      <Lottie
        lottieRef={screenRef}
        autoplay
        loop={false}
        onComplete={() => props.onClick()}
        animationData={require('./../assets/screens/screen13.json')}
        onEnterFrame={frame => {
          switch (parseInt(JSON.parse(JSON.stringify(frame)).currentTime)) {
            case 300:
              if (step < 13) {
                setStep(1)
                screenRef.current?.pause()
              }
              break
            default:
              break
          }
        }}
        style={{
          width: window.innerWidth,
          zoom: 1.4,
        }}
      />
      <Show when={steps[step]?.question ? true : false}>
        <Lottie
          lottieRef={textBubbleRef}
          autoplay
          loop={true}
          animationData={require('./../assets/ui/text_bubble_animation.json')}
          onEnterFrame={() => textBubbleRef.current?.setSpeed(0.25)}
          style={{
            position: 'fixed',
            top: '20%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '30rem',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '20%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
          }}
          className="text-2xl text-center"
        >
          {steps[step].question}
        </h3>
      </Show>
      <Show when={step === 0 || step === 1 || step === 2 || step === 12}>
        <Lottie
          autoplay
          loop={true}
          animationData={require('./../assets/ui/light_burst_animation.json')}
          style={{
            width: window.innerWidth,
            position: 'fixed',
            top: '50%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 10,
          }}
        />
      </Show>
      <Show when={step === 2}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '75%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '10rem',
          }}
        />
      </Show>
      <Show when={step === 3}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '60%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '40%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          {steps[step].questionWithAnswers}
        </h3>
        <img
          src={answerImg}
          style={{
            position: 'fixed',
            top: '63%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
        />
        <p
          style={{
            position: 'fixed',
            top: '63%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
          className="text-l text-center"
          onClick={() => setStep(4)}
        >
          Let's do it
        </p>
        <img
          src={answerImg}
          style={{
            position: 'fixed',
            top: '70%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
        />
        <p
          style={{
            position: 'fixed',
            top: '70%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
          className="text-l text-center"
          onClick={() => setStep(11)}
        >
          Skip for now
        </p>
      </Show>
      <Show when={step === 4}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '30%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '30%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          {steps[step].questionWithAnswers}
        </h3>
        <img
          src={yearlyMaker}
          style={{
            position: 'fixed',
            top: '85%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '20%',
          }}
        />
        <p
          style={{
            position: 'fixed',
            top: '84%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
          className="text-l text-center text-white"
          onClick={() => {
            setYear(1)
            setStep(5)
          }}
        >
          1 yr
        </p>
        <img
          src={yearlyMaker}
          style={{
            position: 'fixed',
            top: '75%',
            left: '80%',
            transform: 'translate(-50%, -50%)',
            width: '20%',
          }}
        />
        <p
          style={{
            position: 'fixed',
            top: '74%',
            left: '80%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
          className="text-l text-center text-white"
          onClick={() => {
            setYear(3)
            setStep(5)
          }}
        >
          3 yr
        </p>
        <img
          src={yearlyMaker}
          style={{
            position: 'fixed',
            top: '65%',
            left: '30%',
            transform: 'translate(-50%, -50%)',
            width: '20%',
          }}
        />
        <p
          style={{
            position: 'fixed',
            top: '64%',
            left: '30%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
          className="text-l text-center text-white"
          onClick={() => {
            setYear(5)
            setStep(5)
          }}
        >
          5 yr
        </p>
      </Show>
      <Show when={step === 5}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '30%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '30%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          {steps[step].questionWithAnswers}
        </h3>
        <img
          src={answerImg}
          style={{
            position: 'fixed',
            top: '63%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
        />
        <p
          style={{
            position: 'fixed',
            top: '63%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
          className="text-l text-center"
          onClick={() => {
            setDomain('Personal')
            setStep(6)
          }}
        >
          Personal
        </p>
        <img
          src={answerImg}
          style={{
            position: 'fixed',
            top: '70%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
        />
        <p
          style={{
            position: 'fixed',
            top: '70%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
          className="text-l text-center"
          onClick={() => {
            setDomain('Social')
            setStep(6)
          }}
        >
          Social
        </p>
        <img
          src={answerImg}
          style={{
            position: 'fixed',
            top: '77%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
        />
        <p
          style={{
            position: 'fixed',
            top: '77%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
          className="text-l text-center"
          onClick={() => {
            setDomain('Professional')
            setStep(6)
          }}
        >
          Professional
        </p>
      </Show>
      <Show when={step === 6}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '30%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '21%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          {steps[step].questionWithAnswers}
        </h3>
        <img
          src={answerImg}
          style={{
            position: 'fixed',
            top: '40%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
        />
        <p
          style={{
            position: 'fixed',
            top: '40%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
          className="text-l text-center"
          onClick={() => setStep(7)}
        >
          Start writing
        </p>
        <img
          src={answerImg}
          style={{
            position: 'fixed',
            top: '47%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
        />
        <a
          style={{
            position: 'fixed',
            top: '47%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
          className="text-l text-center"
          href="mailto:edwige@fireflai.co?subject=Support needed for the Do!&body=Hello team Fireflai%0D%0A
          I need help with the Do (Best Possible Self).%0D%0A
          Can I get in touch with someone or get access to a video that can guide me through the exercise?%0D%0A
          Thank you!"
          target="_blank"
        >
          I need more support
        </a>
        {domain &&
          prompts[domain].map((prm, index) => (
            <Fragment key={index}>
              <img
                src={answerImg}
                style={{
                  position: 'fixed',
                  top: `${70 + Math.floor(index / 2) * 5}%`,
                  left: index % 2 === 0 ? '28%' : '72%',
                  transform: 'translate(-50%, -50%)',
                  width: '40%',
                }}
              />
              <p
                style={{
                  position: 'fixed',
                  top: `${70 + Math.floor(index / 2) * 5}%`,
                  left: index % 2 === 0 ? '28%' : '72%',
                  transform: 'translate(-50%, -50%)',
                  width: '40%',
                }}
                className="text-l text-center"
              >
                {prm}
              </p>
            </Fragment>
          ))}
      </Show>
      <Show when={step === 7}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '68%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
          }}
        />
        <textarea
          style={{
            position: 'fixed',
            top: '43%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '68%',
            height: '39%',
            background: 'transparent',
          }}
          value={text}
          onChange={event => setText(event.target.value)}
        />
        <img
          src={answerImg}
          style={{
            position: 'fixed',
            top: '92%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
        />
        <p
          style={{
            position: 'fixed',
            top: '92%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
          className="text-l text-center"
          onClick={saveText}
        >
          Save
        </p>

        {domain &&
          prompts[domain].map((prm, index) => (
            <Fragment key={index}>
              <img
                src={answerImg}
                style={{
                  position: 'fixed',
                  top: `${70 + Math.floor(index / 2) * 5}%`,
                  left: index % 2 === 0 ? '28%' : '72%',
                  transform: 'translate(-50%, -50%)',
                  width: '40%',
                }}
              />
              <p
                style={{
                  position: 'fixed',
                  top: `${70 + Math.floor(index / 2) * 5}%`,
                  left: index % 2 === 0 ? '28%' : '72%',
                  transform: 'translate(-50%, -50%)',
                  width: '40%',
                }}
                className="text-l text-center"
                onClick={() => setText(text + ' ' + prm + ' ')}
              >
                {prm}
              </p>
            </Fragment>
          ))}
      </Show>
      <Show when={step === 8}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '53%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '45%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          {steps[step].questionWithAnswers}
        </h3>
        <Lottie
          autoplay
          loop={true}
          animationData={require('./../assets/ui/confetti_pop.json')}
          style={{
            width: '15rem',
            position: 'fixed',
            top: '55%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </Show>
      <Show when={step === 9}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '60%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
          }}
        />
        <h2
          style={{
            position: 'fixed',
            top: '20%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-3xl text-center"
        >
          Come back to the do
        </h2>
        <h3
          style={{
            position: 'fixed',
            top: '40%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          You can come back to your visualisation by selecting the same period
          and the same domain.
        </h3>
        <h3
          style={{
            position: 'fixed',
            top: '60%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          You can also start again from scratch by selecting the Do icon from
          your map.
        </h3>
      </Show>
      <Show when={step === 10}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '50%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '45%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          {steps[step].questionWithAnswers}
        </h3>
        <img
          src={steps[step].img2}
          style={{
            position: 'fixed',
            top: '60%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '4rem',
          }}
        />
        <img
          src={steps[step].img3}
          style={{
            position: 'fixed',
            top: '60%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '6rem',
          }}
        />
      </Show>
      <Show when={step === 11}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '60%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '40%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          {steps[step].questionWithAnswers}
        </h3>
      </Show>
      <Show when={step === 11}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '60%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '30%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          {steps[step].questionWithAnswers}
        </h3>
        <h3
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          It will be added to your map and you can come back to it in the
          future.
        </h3>
        <img
          src={steps[step].img2}
          style={{
            position: 'fixed',
            top: '70%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '6rem',
          }}
        />
      </Show>
    </div>
  )
}
export default Screen13
