import { useRef, useState, useMemo } from 'react'

import Lottie, { LottieRefCurrentProps } from 'lottie-react'

import { Show } from '../helpers/Conditionals'
import learnIcon from './../assets/svg/static_learn_icon.svg'
import uiPanel1 from './../assets/svg/lighten_up_UI_panel_1.svg'
import answerImg from './../assets/svg/generic_white_UI_button_smaller.svg'
import uiPanel2 from './../assets/svg/lighten_up_UI_panel_2.svg'

const Screen6 = (props: { onClick: () => void; startMeasure: () => void }) => {
  const [step, setStep] = useState<number>(0)
  const [onClick, setOnClick] = useState<'NEXT' | 'MEASURE'>('NEXT')
  const screenRef = useRef<LottieRefCurrentProps>(null)
  const textBubbleRef = useRef<LottieRefCurrentProps>(null)
  const steps = useMemo(
    () => [
      {
        question: '',
        onClick: () => {},
      },
      {
        question: 'So now let’s prepare ourselves for the next clouds',
        onClick: () => setStep(2),
      },
      {
        question:
          'Did you notice? There is a new element on your path: the first Learn icon. Tap on it.',
        img: learnIcon,
        onClick: () => setStep(3),
      },
      {
        questionWithAnswers: 'Do you know what emotions and moods are?',
        img: uiPanel1,
        onClick: () => {},
      },
      {
        question: 'So now let’s talk about Emotions',
        onClick: () => setStep(5),
      },
      {
        question: 'Emotions are very intense feelings',
        onClick: () => setStep(6),
      },
      {
        question: 'created by a chemical response',
        onClick: () => setStep(7),
      },
      {
        question: 'that last seconds to a few minutes',
        onClick: () => setStep(8),
      },
      {
        question: 'often triggered by an external event',
        onClick: () => setStep(9),
      },
      {
        question: 'Emotions can even affect your facial expressions',
        onClick: () => {
          setStep(11)
          screenRef.current?.setDirection(1)
        },
      },
      {
        img: uiPanel2,
        img2: learnIcon,
        text: 'And here is your Learn icon!',
        onClick: () => {
          setOnClick('MEASURE')
          setStep(11)
          screenRef.current?.setDirection(1)
        },
      },
      {
        question: '',
        onClick: () => {},
      },
    ],
    []
  )

  return (
    <div
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
        padding: 0,
        overflow: 'hidden',
      }}
      onClick={steps[step].onClick}
    >
      <Lottie
        lottieRef={screenRef}
        autoplay
        loop={false}
        onComplete={onClick === 'NEXT' ? props.onClick : props.startMeasure}
        animationData={require('./../assets/screens/screen6.json')}
        onEnterFrame={frame => {
          switch (parseInt(JSON.parse(JSON.stringify(frame)).currentTime)) {
            case 199:
              setStep(1)
              break
            case 200:
              screenRef.current?.setDirection(1)
              break
            case 250:
              step < 11 ? screenRef.current?.setDirection(-1) : null
              break
            default:
              break
          }
        }}
        style={{
          width: window.innerWidth,
          zoom: 1.4,
        }}
      />
      <Show when={steps[step]?.question ? true : false}>
        <Lottie
          lottieRef={textBubbleRef}
          autoplay
          loop={true}
          animationData={require('./../assets/ui/text_bubble_animation.json')}
          onEnterFrame={() => textBubbleRef.current?.setSpeed(0.25)}
          style={{
            position: 'fixed',
            top: '20%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '30rem',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '20%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
          }}
          className="text-2xl text-center"
        >
          {steps[step].question}
        </h3>
      </Show>
      <Show when={step === 2}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '75%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '10rem',
          }}
        />
      </Show>
      <Show when={step === 3}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '25%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '20%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          {steps[step].questionWithAnswers}
        </h3>
        <img
          src={answerImg}
          style={{
            position: 'fixed',
            top: '30%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
          onClick={event => {
            event.preventDefault()
            setStep(4)
          }}
        />
        <p
          style={{
            position: 'fixed',
            top: '30%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
          className="text-l text-center"
          onClick={event => {
            event.preventDefault()
            setStep(4)
          }}
        >
          No, tell me more
        </p>
        <img
          src={answerImg}
          style={{
            position: 'fixed',
            top: '37%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
          onClick={event => {
            event.preventDefault()
            setStep(10)
          }}
        />
        <p
          style={{
            position: 'fixed',
            top: '37%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
          }}
          className="text-l text-center"
          onClick={event => {
            event.preventDefault()
            setStep(10)
          }}
        >
          Yes, let’s skip for now
        </p>
      </Show>
      <Show when={step === 10}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '45%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '30%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          {steps[step].text}
        </h3>
        <p
          style={{
            position: 'fixed',
            top: '47%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            listStyleType: 'disc',
          }}
          className="text-xl"
        >
          It will be added to your map and you can come back to it in the future
        </p>
        <img
          src={steps[step].img2}
          style={{
            position: 'fixed',
            top: '60%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '6rem',
          }}
        />
      </Show>
    </div>
  )
}
export default Screen6
