export function Hide(props: { when: boolean; children?: React.ReactNode }) {
  if (props.when) {
    return null
  }
  return <>{props.children}</>
}

export function Show(props: { when: boolean; children: React.ReactNode }) {
  if (!props.when) {
    return null
  }
  return <>{props.children}</>
}
