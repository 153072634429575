import { useRef, useState, useMemo } from 'react'

import Lottie, { LottieRefCurrentProps } from 'lottie-react'

import { Show } from '../helpers/Conditionals'
import light from './../assets/svg/single_firely_to_animate.svg'
import tapIconCircle from './../assets/svg/tap_icon_just_the_circle_around.svg'

const Screen2 = (props: { onClick: () => void }) => {
  const [step, setStep] = useState<number>(0)
  const screenRef = useRef<LottieRefCurrentProps>(null)
  const iconRef = useRef<LottieRefCurrentProps>(null)
  const textBubbleRef = useRef<LottieRefCurrentProps>(null)
  const steps = useMemo(
    () => [
      { onClick: () => {} },
      {
        question: 'Welcome to Fireflai!',
        onClick: () => setStep(2),
      },
      {
        question: 'It’s time to start your journey',
        onClick: () => {
          setStep(3)
          screenRef.current?.play()
        },
      },
      {
        question: 'First, you will see a firefly appear...',
        onClick: () => setStep(4),
      },
      { question: 'Tap on it and see what happens', onClick: () => setStep(5) },
      {
        img: light,
        img2: tapIconCircle,
        onClick: () => {
          setStep(6)
          screenRef.current?.play()
        },
      },
      {
        onClick: () => {
          setStep(7)
          screenRef.current?.play()
        },
      },
      {
        question:
          'Awesome! The firefly will be your guide through your journey',
        onClick: () => setStep(8),
      },
      {
        question: '...a journey to explore your psychology in a new way',
        onClick: () => {
          setStep(9)
          screenRef.current?.play()
        },
      },
      { onClick: () => props.onClick() },
    ],
    []
  )

  return (
    <div
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
        padding: 0,
        overflow: 'hidden',
      }}
      onClick={steps[step].onClick}
    >
      <Lottie
        lottieRef={screenRef}
        autoplay
        loop={false}
        onComplete={() => props.onClick()}
        animationData={require('./../assets/screens/screen2.json')}
        onEnterFrame={frame => {
          switch (parseInt(JSON.parse(JSON.stringify(frame)).currentTime)) {
            case 200:
              setStep(1)
              break
            case 478:
              step < 3 ? screenRef.current?.pause() : null
              break
            case 740:
              step < 6 ? screenRef.current?.pause() : null
              break
            case 950:
              step < 9 ? screenRef.current?.pause() : null
              break
            default:
              break
          }
        }}
        style={{
          width: window.innerWidth,
          zoom: 1.4,
        }}
      />
      <Show when={steps[step]?.question ? true : false}>
        <Lottie
          lottieRef={textBubbleRef}
          autoplay
          loop={true}
          animationData={require('./../assets/ui/text_bubble_animation.json')}
          onEnterFrame={() => textBubbleRef.current?.setSpeed(0.25)}
          style={{
            position: 'fixed',
            top: '20%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '30rem',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '20%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
          }}
          className="text-2xl text-center"
        >
          {steps[step].question}
        </h3>
      </Show>
      <Show when={step === 1}>
        <Lottie
          lottieRef={iconRef}
          autoplay
          loop={true}
          animationData={require('./../assets/ui/tap_icon.json')}
          initialSegment={[100, 950]}
          onLoopComplete={() => {
            iconRef.current?.playSegments([500, 620], true)
          }}
          onEnterFrame={() => iconRef.current?.setSpeed(2)}
          style={{
            width: '5rem',
            position: 'fixed',
            top: '75%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </Show>
      <Show when={step === 5}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '70%',
            left: '75%',
            transform: 'translate(-50%, -50%)',
            width: '4rem',
          }}
        />
        <img
          src={steps[step].img2}
          style={{
            position: 'fixed',
            top: '70%',
            left: '75%',
            transform: 'translate(-50%, -50%)',
            width: '3rem',
          }}
        />
      </Show>
      <Show when={step === 6}>
        <Lottie
          autoplay
          loop={false}
          onComplete={() => setStep(7)}
          animationData={require('./../assets/ui/light_burst_animation.json')}
          style={{
            width: window.innerWidth,
            position: 'fixed',
            top: '50%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 10,
          }}
        />
      </Show>
      <Show when={step === 7}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '70%',
            left: '25%',
            transform: 'translate(-50%, -50%)',
            width: '4rem',
          }}
        />
        <img
          src={steps[step].img2}
          style={{
            position: 'fixed',
            top: '70%',
            left: '25%',
            transform: 'translate(-50%, -50%)',
            width: '3rem',
          }}
        />
      </Show>
    </div>
  )
}
export default Screen2
