import { useRef, useState, useMemo } from 'react'

import Lottie, { LottieRefCurrentProps } from 'lottie-react'

import { Show } from '../helpers/Conditionals'
import rewardPanelImg from './../assets/svg/firefly_reward_panel_lighten_up_tutorial_1.svg'
import rewardPanelImg2 from './../assets/svg/firefly_reward_panel_lighten_up_tutorial_2.svg'
import light from './../assets/svg/single_firely_to_animate.svg'
import tapIconCircle from './../assets/svg/tap_icon_just_the_circle_around.svg'
import jar from './../assets/svg/jar_frame.svg'

const Screen4 = (props: { onClick: () => void }) => {
  const [step, setStep] = useState<number>(0)
  const screenRef = useRef<LottieRefCurrentProps>(null)
  const iconRef = useRef<LottieRefCurrentProps>(null)
  const steps = useMemo(
    () => [
      {
        onClick: () => {},
      },
      {
        onClick: () => setStep(2),
      },
      {
        img: light,
        img2: tapIconCircle,
        onClick: () => setStep(3),
      },
      {
        question: 'You collected your first firefly',
        img: rewardPanelImg,
        onClick: () => setStep(4),
      },
      {
        question:
          'You fill up your jar with all the fireflies you collect on the journey',
        img: rewardPanelImg2,
        img2: jar,
        img3: light,
        onClick: () => setStep(5),
      },
      {
        question: 'So let’s collect as many as possible during your journey! ',
        img: rewardPanelImg2,
        onClick: () => setStep(6),
      },
      {
        question: 'Now, let’s begin',
        img: rewardPanelImg2,
        onClick: () => {
          setStep(7)
          screenRef.current?.setDirection(1)
        },
      },
      {
        question: '',
        onClick: () => {},
      },
    ],
    []
  )

  return (
    <div
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
        padding: 0,
        overflow: 'hidden',
      }}
      onClick={steps[step].onClick}
    >
      <Lottie
        lottieRef={screenRef}
        autoplay
        loop={false}
        onComplete={() => props.onClick()}
        animationData={require('./../assets/screens/screen4.json')}
        onEnterFrame={frame => {
          switch (parseInt(JSON.parse(JSON.stringify(frame)).currentTime)) {
            case 200:
              setStep(1)
              break
            case 207:
              screenRef.current?.setSpeed(2)
              screenRef.current?.setDirection(1)
              break
            case 825:
              step < 2
                ? screenRef.current?.setDirection(-1)
                : screenRef.current?.setSpeed(1.5)
              break
            case 1100:
              screenRef.current?.setDirection(1)
              screenRef.current?.setSpeed(20)
              break
            case 1200:
              if (step < 7) {
                screenRef.current?.setDirection(-1)
              } else {
                screenRef.current?.setDirection(1)
                screenRef.current?.setSpeed(1.5)
              }
              break
            default:
              break
          }
        }}
        style={{
          width: window.innerWidth,
          zoom: 1.4,
        }}
      />
      <Show when={steps[step]?.question ? true : false}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '25%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
          }}
        />
        <h3
          style={{
            position: 'fixed',
            top: '20%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
          }}
          className="text-2xl text-center"
        >
          {steps[step].question}
        </h3>
      </Show>
      <Show when={step === 1}>
        <Lottie
          lottieRef={iconRef}
          autoplay
          loop={true}
          animationData={require('./../assets/ui/tap_icon.json')}
          initialSegment={[100, 950]}
          onLoopComplete={() => {
            iconRef.current?.playSegments([500, 620], true)
          }}
          onEnterFrame={() => iconRef.current?.setSpeed(2)}
          style={{
            width: '5rem',
            position: 'fixed',
            top: '75%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </Show>
      <Show when={step === 2}>
        <img
          src={steps[step].img}
          style={{
            position: 'fixed',
            top: '70%',
            left: '25%',
            transform: 'translate(-50%, -50%)',
            width: '8rem',
          }}
        />
        <img
          src={steps[step].img2}
          style={{
            position: 'fixed',
            top: '70%',
            left: '25%',
            transform: 'translate(-50%, -50%)',
            width: '6rem',
          }}
        />
      </Show>
      <Show when={step === 4}>
        <img
          src={steps[step].img2}
          style={{
            position: 'fixed',
            top: '35%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '4rem',
          }}
        />
        <img
          src={steps[step].img3}
          style={{
            position: 'fixed',
            top: '35%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
            width: '6rem',
          }}
        />
      </Show>
      <Show when={step === 6}>
        <Lottie
          autoplay
          loop={true}
          animationData={require('./../assets/ui/tap_icon.json')}
          initialSegment={[200, 950]}
          style={{
            position: 'fixed',
            top: '35%',
            left: ' 50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </Show>
    </div>
  )
}
export default Screen4
