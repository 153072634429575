import { useRef, useState, useEffect } from 'react'

import Lottie, { LottieRefCurrentProps } from 'lottie-react'

const Screen1 = (props: { onClick: () => void }) => {
  const screenRef = useRef<LottieRefCurrentProps>(null)
  const logoRef = useRef<LottieRefCurrentProps>(null)
  const iconRef = useRef<LottieRefCurrentProps>(null)
  const [nextScreen, setNextScreen] = useState<boolean>(false)

  useEffect(() => {
    if (nextScreen) {
      screenRef.current?.playSegments([300, 391], true)
      logoRef.current?.playSegments([400, 522], true)
      iconRef.current?.playSegments([950, 1080], true)
    }
  }, [nextScreen])

  return (
    <div
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
        padding: 0,
        overflow: 'hidden',
      }}
      onClick={() => setNextScreen(true)}
    >
      <Lottie
        lottieRef={screenRef}
        autoplay
        loop={false}
        animationData={require('./../assets/screens/screen1.json')}
        initialSegment={[0, 320]}
        onEnterFrame={frame =>
          nextScreen &&
          JSON.parse(JSON.stringify(frame)).currentTime ===
            JSON.parse(JSON.stringify(frame)).totalTime - 1
            ? props.onClick()
            : null
        }
        style={{
          width: window.innerWidth,
          zoom: 1.4,
        }}
      />
      <Lottie
        lottieRef={logoRef}
        autoplay
        loop={true && !nextScreen}
        animationData={require('./../assets/ui/loading_screen_logo_animation.json')}
        initialSegment={[0, 400]}
        onLoopComplete={() => {
          logoRef.current?.playSegments([300, 400], true)
        }}
        style={{
          position: 'fixed',
          top: '10%',
          left: ' 50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
      <Lottie
        lottieRef={iconRef}
        autoplay
        loop={true && !nextScreen}
        animationData={require('./../assets/ui/tap_icon.json')}
        initialSegment={[100, 950]}
        onLoopComplete={() => {
          iconRef.current?.playSegments([500, 620], true)
        }}
        style={{
          position: 'fixed',
          width: '5rem',
          top: '80%',
          left: ' 50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    </div>
  )
}
export default Screen1
