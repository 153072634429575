import { useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { Show } from './helpers/Conditionals'
import ScreenHome from './layouts/ScreenHome'
import ScreenMap from './layouts/ScreenMap'
import ScreenData from './layouts/ScreenData'
import Screen1 from './layouts/Screen1'
import Screen2 from './layouts/Screen2'
import Screen3 from './layouts/Screen3'
import Screen4 from './layouts/Screen4'
import Screen5 from './layouts/Screen5'
import Screen6 from './layouts/Screen6'
import Screen7 from './layouts/Screen7'
import Screen8 from './layouts/Screen8'
import Screen9 from './layouts/Screen9'
import Screen10 from './layouts/Screen10'
import Screen11 from './layouts/Screen11'
import Screen12 from './layouts/Screen12'
import Screen13 from './layouts/Screen13'
import Screen14 from './layouts/Screen14'
import Screen3Transition from './layouts/Screen3Transition'

export enum ActiveLayout {
  Screen1 = 'Screen1',
  Screen2 = 'Screen2',
  Screen3 = 'Screen3',
  Screen4 = 'Screen4',
  Screen5 = 'Screen5',
  Screen6 = 'Screen6',
  Screen7 = 'Screen7',
  Screen8 = 'Screen8',
  Screen9 = 'Screen9',
  Screen10 = 'Screen10',
  Screen11 = 'Screen11',
  Screen12 = 'Screen12',
  Screen13 = 'Screen13',
  Screen14 = 'Screen14',
  ScreenHome = 'ScreenHome',
  ScreenMap = 'ScreenMap',
  ScreenData = 'ScreenData',
}

const activeLayoutBackground = (activeLayout: ActiveLayout) => {
  switch (activeLayout) {
    case ActiveLayout.ScreenHome:
      return 'linear-gradient(0deg, rgba(255,223,154,1) 0%, rgba(235,129,93,1) 100%)'
    case ActiveLayout.Screen1:
      return 'linear-gradient(0deg, rgba(255,223,154,1) 0%, rgba(235,129,93,1) 100%)'
    case ActiveLayout.Screen2:
      return 'linear-gradient(0deg, rgba(249,255,224,1) 0%, rgba(255,251,219,1) 33%, rgba(255,191,125,1) 76%, rgba(235,129,93,1) 100%)'
    case ActiveLayout.Screen3:
      'linear-gradient(0deg, rgba(249,255,224,1) 0%, rgba(255,251,219,1) 33%, rgba(255,191,125,1) 76%, rgba(235,129,93,1) 100%)'
    case ActiveLayout.ScreenMap:
      'linear-gradient(0deg, rgba(249,255,224,1) 0%, rgba(255,251,219,1) 33%, rgba(255,191,125,1) 76%, rgba(235,129,93,1) 100%)'
    case ActiveLayout.Screen4:
      return 'linear-gradient(0deg, rgba(255,255,255,1) 20%, rgba(247,216,131,1) 41%, rgba(248,183,92,1) 68%, rgba(223,123,84,1) 100%)'
    case ActiveLayout.Screen5:
      return 'linear-gradient(0deg, rgba(253,241,241,1) 0%, rgba(253,217,144,1) 46%, rgba(247,123,70,1) 100%)'
    case ActiveLayout.Screen6:
      return 'linear-gradient(0deg, rgba(209,255,214,1) 38%, rgba(137,236,214,1) 62%, rgba(37,161,163,1) 100%)'
    case ActiveLayout.Screen7:
      return 'linear-gradient(0deg, rgba(209,255,214,1) 38%, rgba(137,236,214,1) 62%, rgba(37,161,163,1) 100%)'
    case ActiveLayout.Screen8:
      return 'linear-gradient(0deg, rgba(255,255,255,1) 25%, rgba(255,253,189,1) 64%, rgba(23,201,232,1) 100%)'
    case ActiveLayout.Screen9:
      return 'linear-gradient(0deg, rgba(243,249,228,1) 3%, rgba(179,210,204,1) 63%, rgba(0,166,156,1) 100%)'
    case ActiveLayout.Screen10:
      return 'linear-gradient(0deg, rgba(255,255,255,1) 32%, rgba(251,205,143,1) 55%, rgba(249,157,102,1) 78%, rgba(225,94,115,1) 100%)'
    default:
      return 'white'
  }
}

const mobileCheck = function () {
  let check = false
  // eslint-disable-next-line
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    ) {
      check = true
    }
    // eslint-disable-next-line
  })(navigator.userAgent || navigator.vendor || (window as any).opera)
  return check
}

function App() {
  const [activeLayout, setActiveLayout] = useState<ActiveLayout>(
    ActiveLayout[
      localStorage.getItem('MaxLayout') === 'ScreenHome'
        ? 'ScreenHome'
        : (localStorage.getItem('ActiveLayout') as keyof typeof ActiveLayout) ??
          'Screen1'
    ]
  )
  const [screen3TransitionIn, setScreen3TransitionIn] = useState<boolean>(false)
  const [screen3TransitionOut, setScreen3TransitionOut] =
    useState<boolean>(false)
  const [showSupportModal, setShowSupportModal] = useState<boolean>(false)

  return (
    <>
      {mobileCheck() && (
        <BrowserRouter>
          <Routes>
            <Route
              path="*"
              element={
                <div
                  style={{ background: activeLayoutBackground(activeLayout) }}
                >
                  <Show when={activeLayout === ActiveLayout.Screen1}>
                    <Screen1
                      onClick={() => setActiveLayout(ActiveLayout.Screen2)}
                    />
                  </Show>
                  <Show when={activeLayout === ActiveLayout.Screen2}>
                    <Screen2 onClick={() => setScreen3TransitionIn(true)} />
                  </Show>
                  <Show when={screen3TransitionIn}>
                    <Screen3Transition
                      toDisplay={() => setActiveLayout(ActiveLayout.Screen3)}
                      onComplete={() => setScreen3TransitionIn(false)}
                    />
                  </Show>
                  <Show when={activeLayout === ActiveLayout.Screen3}>
                    <Screen3 onClick={() => setScreen3TransitionOut(true)} />
                  </Show>
                  <Show when={screen3TransitionOut}>
                    <Screen3Transition
                      toDisplay={() => {
                        setActiveLayout(ActiveLayout.Screen4)
                        localStorage.setItem(
                          'ActiveLayout',
                          ActiveLayout.Screen4
                        )
                      }}
                      onComplete={() => setScreen3TransitionOut(false)}
                    />
                  </Show>
                  <Show when={activeLayout === ActiveLayout.Screen4}>
                    <Screen4
                      onClick={() => {
                        setActiveLayout(ActiveLayout.Screen5)
                        localStorage.setItem(
                          'ActiveLayout',
                          ActiveLayout.Screen5
                        )
                      }}
                    />
                  </Show>
                  <Show when={activeLayout === ActiveLayout.Screen5}>
                    <Screen5
                      onClick={() => {
                        setActiveLayout(ActiveLayout.Screen6)
                        localStorage.setItem(
                          'ActiveLayout',
                          ActiveLayout.Screen6
                        )
                      }}
                    />
                  </Show>
                  <Show when={activeLayout === ActiveLayout.Screen6}>
                    <Screen6
                      onClick={() => {
                        setActiveLayout(ActiveLayout.Screen7)
                        localStorage.setItem(
                          'ActiveLayout',
                          ActiveLayout.Screen7
                        )
                      }}
                      startMeasure={() => {
                        setActiveLayout(ActiveLayout.Screen12)
                        localStorage.setItem(
                          'ActiveLayout',
                          ActiveLayout.Screen12
                        )
                      }}
                    />
                  </Show>
                  <Show when={activeLayout === ActiveLayout.Screen7}>
                    <Screen7
                      onClick={() => {
                        setActiveLayout(ActiveLayout.Screen8)
                        localStorage.setItem(
                          'ActiveLayout',
                          ActiveLayout.Screen8
                        )
                      }}
                    />
                  </Show>
                  <Show when={activeLayout === ActiveLayout.Screen8}>
                    <Screen8
                      onClick={() => {
                        setActiveLayout(ActiveLayout.Screen9)
                        localStorage.setItem(
                          'ActiveLayout',
                          ActiveLayout.Screen9
                        )
                      }}
                    />
                  </Show>
                  <Show when={activeLayout === ActiveLayout.Screen9}>
                    <Screen9
                      onClick={() => {
                        setActiveLayout(ActiveLayout.Screen10)
                        localStorage.setItem(
                          'ActiveLayout',
                          ActiveLayout.Screen10
                        )
                      }}
                    />
                  </Show>
                  <Show when={activeLayout === ActiveLayout.Screen10}>
                    <Screen10
                      onClick={() => {
                        setActiveLayout(ActiveLayout.Screen11)
                        localStorage.setItem(
                          'ActiveLayout',
                          ActiveLayout.Screen11
                        )
                      }}
                    />
                  </Show>
                  <Show when={activeLayout === ActiveLayout.Screen11}>
                    <Screen11
                      onClick={() => {
                        setActiveLayout(ActiveLayout.Screen12)
                        localStorage.setItem(
                          'ActiveLayout',
                          ActiveLayout.Screen12
                        )
                      }}
                    />
                  </Show>
                  <Show when={activeLayout === ActiveLayout.Screen12}>
                    <Screen12
                      onClick={() => {
                        setActiveLayout(ActiveLayout.Screen13)
                        localStorage.setItem(
                          'ActiveLayout',
                          ActiveLayout.Screen13
                        )
                      }}
                    />
                  </Show>
                  <Show when={activeLayout === ActiveLayout.Screen13}>
                    <Screen13
                      onClick={() => {
                        setActiveLayout(ActiveLayout.Screen14)
                        localStorage.setItem(
                          'ActiveLayout',
                          ActiveLayout.Screen14
                        )
                      }}
                    />
                  </Show>
                  <Show when={activeLayout === ActiveLayout.Screen14}>
                    <Screen14
                      onClick={() => {
                        setShowSupportModal(true)
                        setActiveLayout(ActiveLayout.ScreenHome)
                        localStorage.setItem(
                          'ActiveLayout',
                          ActiveLayout.ScreenHome
                        )
                        localStorage.setItem(
                          'MaxLayout',
                          ActiveLayout.ScreenHome
                        )
                      }}
                    />
                  </Show>
                  <Show when={activeLayout === ActiveLayout.ScreenHome}>
                    <ScreenHome
                      showMap={() => setActiveLayout(ActiveLayout.ScreenMap)}
                      showData={() => setActiveLayout(ActiveLayout.ScreenData)}
                      setShowSupportModal={setShowSupportModal}
                      showSupportModal={showSupportModal}
                    />
                  </Show>
                  <Show when={activeLayout === ActiveLayout.ScreenMap}>
                    <ScreenMap
                      startLearn={() => setActiveLayout(ActiveLayout.Screen4)}
                      startMeasure={() =>
                        setActiveLayout(ActiveLayout.Screen12)
                      }
                      startDo={() => setActiveLayout(ActiveLayout.Screen13)}
                      showScreenHome={() =>
                        setActiveLayout(ActiveLayout.ScreenHome)
                      }
                    />
                  </Show>
                  <Show when={activeLayout === ActiveLayout.ScreenData}>
                    <ScreenData
                      showScreenHome={() =>
                        setActiveLayout(ActiveLayout.ScreenHome)
                      }
                    />
                  </Show>
                </div>
              }
            />
          </Routes>
        </BrowserRouter>
      )}
      <Show when={!mobileCheck()}>
        <p
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          className="text-3xl"
        >
          In order to access the app, please use your smartphone.
        </p>
      </Show>
    </>
  )
}
export default App
