import { useRef, useState } from 'react'

import Lottie, { LottieRefCurrentProps } from 'lottie-react'
import closeButton from './../assets/svg/close_icon.svg'
import { Show } from '../helpers/Conditionals'

export enum Data {
  none = 'none',
  small = 'small',
  medium = 'medium',
  big = 'big',
}
export enum DataAnim {
  none = 'data_screen_1_small_light.json',
  small = 'data_screen_1_small_light.json',
  medium = 'data_screen_2_medium_light.json',
  big = 'data_screen_3_bigger_light.json',
}
const ScreenData = (props: { showScreenHome: () => void }) => {
  const screenRef = useRef<LottieRefCurrentProps>(null)
  const [data] = useState<Data>(
    Data[(localStorage.getItem('Data') as keyof typeof Data) ?? 'none']
  )
  const [showText, setShowText] = useState<boolean>(false)
  return (
    <div
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
        padding: 0,
        overflow: 'hidden',
      }}
    >
      <Lottie
        lottieRef={screenRef}
        autoplay
        loop={false}
        animationData={require('./../assets/screens/' + DataAnim[data])}
        onEnterFrame={frame => {
          screenRef.current?.setSpeed(2)
          if (data === Data.none) {
            switch (parseInt(JSON.parse(JSON.stringify(frame)).currentTime)) {
              case 320:
                setShowText(true)
                screenRef.current?.pause()
                break
              default:
                break
            }
          }
        }}
        style={{
          width: window.innerWidth,
          zoom: 1.4,
          transform: 'translate(-50%, -50%)',
          position: 'fixed',
          left: '50%',
          top: '50%',
        }}
      />
      <Show when={showText}>
        <div
          className="flex"
          style={{
            position: 'fixed',
            top: '20%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            background: 'rgba(46, 50, 71, 0.7)',
            color: 'white',
          }}
        >
          Your data will be stored here when you finish Measure
        </div>
      </Show>
      <span onClick={() => props.showScreenHome()}>
        <img
          src={closeButton}
          style={{
            position: 'fixed',
            top: '5%',
            left: ' 90%',
            transform: 'translate(-50%, -50%)',
            width: '10%',
          }}
          onClick={() => props.showScreenHome()}
        />
      </span>
    </div>
  )
}
export default ScreenData
